.projectm {
  padding: 30px 0;
  padding-bottom: 50px;
}

.projectm_flex {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(2, minmax(350px, 50%));
}

.projectm_cards {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 15px;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.projectm_info_box {
  transform: translateY(200px);
  transition: all 0.5s ease-in-out;
}

.projectm_cards_title {
  font-size: 26px;
  line-height: 34px;
  font-family: "SF Pro", sans-serif;
  color: #fff;
  bottom: 0;
  margin-bottom: 20px;
  font-weight: 500;
  letter-spacing: 2px;
  transition: all 0.5s ease-in-out;
}

.projectm_cards_info {
  height: 200px;
  font-size: 18px;
  letter-spacing: 2px;
  font-family: "SF Pro", sans-serif;
  font-weight: 500;
  color: #fff;
  line-height: 24px;
  margin-bottom: 10px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.projectm_cards:hover {
  backdrop-filter: blur(10px);
  background-color: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0.3) x
  );
}

.projectm_cards:hover .projectm_info_box {
  transform: translateY(-20px);
}

.projectm_cards:hover .projectm_cards_info {
  opacity: 1;
}

.dd {
  color: var(--main-color-yellow);
}

.btn_style_con:hover .dd {
  color: #fff;
}

.all_projectsm_btn_box {
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.aboutm_headName {
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  opacity: 0.99;
}

@media (max-width: 770px) {
  .projectm .container {
    width: 100%;
    padding: 0 !important;
  }
  .projectm {
    padding: 30px 15px;
  }
  .projectm_flex {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(1, minmax(450px, 100%));
  }
  .projectm_cards {
    max-height: 470px;
    height: 100%;
    background-size: 100% 100%;
  }
  .projectm_cards_info {
    font-size: 14px;
    line-height: 20px;
    width: 100%;
  }
}

@media (max-width: 460px) {
  .projectm_cards {
    max-height: 380px;
    height: 100%;
    background-size: 100% 100%;
    width: 74%;
  }
}
