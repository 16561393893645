.partner {
  padding: 30px 0;
}

.partner-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(33.3%, 250px));
  width: 100%;
  margin: 0 auto;
}

.partner-img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

@media (max-width: 550px) {
  .partner-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(50%, 250px));
    width: 100%;
    margin: 0 auto;
  }

  .partner-img {
    width: 100%;
    height: 280px;
    object-fit: cover;
  }
}
