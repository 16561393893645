.modalContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0%;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(18px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-modal .video-modal {
  background: rgba(0, 0, 0, 0.5);
  padding: 2rem;
  transition: all 0.4s ease;
}

.modal_content-video {
  width: 991px;
  height: 484px;
  border-radius: 0 0 20px 20px;
  background-color: #fff;
  margin-top: 100px;
}

.modalContainer.show {
  visibility: visible;
  transition: all 0.2s ease !important;
}

.modalContainer.show .video-modal {
  opacity: 1;
  animation-name: fadeon;
  animation-iteration-count: 1;
  animation-duration: 0.5s;
  display: block;
}
