.sidebar {
  background: #222936;
  padding: 10px 0;
}

.sidebar-list {
  display: flex;
  align-items: center;
  list-style: none;
  margin-left: -20px;
}

.sidebar-link {
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #6f747c;
  transition: 0.5s;
  display: block;
  position: relative;
  padding-bottom: 5px;
}

.sidebar-link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  background: #fff;
  border-radius: 23px 10px 0px 0px;
  transition: 0.3s;
}

.sidebar-link:hover::before {
  transform: scaleX(1);
}

.sidebar-link:hover {
  color: #fff;
}

.sidebar-item {
  margin: 0 30px;
}
