.sublime {
  background-color: #fce8bd;
  padding-top: 70px;
  padding-bottom: 100px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: -20px;
}

.sublime-img {
  width: 550px;
}

.sublime-name {
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 50px;
  color: #222936;
  width: 600px;
  margin-bottom: 0;
}

.sublime-btn {
  background: #222936;
  width: 44px;
  height: 42px;
  cursor: pointer;
}

.sublime-page {
  display: flex;
}

.sublime-right {
  margin: 0 50px;
}

.sublime-span {
  border: 1px solid #222936;
  width: 115px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  cursor: pointer;
}

.sublime-title {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
.sublime-names {
  margin-bottom: 0;
  color: #141414;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
}

.sublime-subnames {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #545658;
  margin-top: 0;
}

.sublime-text {
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #141414;
  width: 600px;
}

.sublime-item {
  background: rgba(246, 246, 246, 0.5);
  width: 255px;
  height: 85.6px;
  padding: 12px 13px;
  margin: 15px 0;
}

.sublime-texts {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 15.3px;
  line-height: 19px;
  color: #141414;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sublime-spans {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 11.05px;
  line-height: 14px;
  color: #545658;
}

.sublime-subtext {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #545658;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
}

.sublime-subtext span {
  height: 25px;
  width: 2px;
  background: #8a8c8e;
  margin-left: 0;
}
