.modalContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0%;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(18px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal .modal {
  background: rgba(0, 0, 0, 0.5);
  padding: 2rem;
  transition: all 0.4s ease;
}

.modal_content {
  width: 991px;
  height: 484px;
  background: #fce8bd;
  border-radius: 20px;
  margin-top: 60px;
}
.modalContainer.show {
  visibility: visible;
  transition: all 0.2s ease !important;
}

.modalContainer.show .modal {
  opacity: 1;
  animation-name: fadeon;
  animation-iteration-count: 1;
  animation-duration: 0.5s;
  display: block;
}

@media (max-width: 1440px) {
  .container {
    width: 100%;
  }

  .modal .header-close {
    left: 82%;
  }
}

@media (max-width: 1280px) {
  .container {
    width: 100%;
  }
  .modal .header-close {
    left: 80%;
  }
}

@media (max-width: 1080px) {
  .container {
    width: 100%;
  }
  .modal .header-close {
    left: 93%;
    top: 3%;
    margin-top: 25px;
    position: relative;
  }
  .modal .header-modal {
    width: 100%;
  }
  body .modal_content {
    width: 100%;
  }
}

@media (max-width: 780px) {
  .container {
    width: 100%;
  }
  body .modal_content {
    width: 500px;
  }

  body .header-imgs {
    display: none;
  }
  body .header-input {
    width: 80%;
  }
  .modal .header-modal {
    width: 100%;
  }
  .modal .header-right {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .modal .header-submit {
    width: 90%;
  }

  .modal .header-form {
    justify-content: center;
    align-items: center;
  }

  .header-subname,
  .header-subnames {
    margin-left: 20px;
  }
}

@media (max-width: 500px) {
  .container {
    width: 100%;
  }

  body .modal_content {
    width: 100%;
  }

  body .modal {
    width: 90%;
  }

  .modal .header-close {
    left: 91%;
  }
}
