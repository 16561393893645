.nav--langs p {
  color: black;
  text-transform: uppercase;
  margin: 10px;
  font-size: 18px;
  font-weight: 600;
}

.nav--langs {
  display: flex;
  align-items: center;
  margin-left: -10px;
}
