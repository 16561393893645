.gallery {
  padding: 30px 0;
}

.gallery-list {
  display: grid;
  grid-template-columns: repeat(4, minmax(25%, 250px));
  width: 100%;
}

.gallery-img {
  width: 100%;
}

@media (max-width: 1100px) {
  .gallery-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(33.3%, 250px));
    width: 100%;
  }
}

@media (max-width: 550px) {
  .gallery-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(50%, 250px));
    width: 100%;
  }
}
