.navbar {
  position: fixed;
  width: 100%;
  z-index: 1001;
  top: -15px;
  padding-bottom: 0;
  padding-top: 15px;
  bottom: 88%;
}

.actives {
  color: #000;
  background: #fce8bd;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
svg path {
  fill: #fff;
}

.navbar-names {
  font-size: 25px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.actives svg path {
  fill: #000;
}

.navbar-list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-item {
  margin: 0 10px;
}

.navbar-link {
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 15.1852px;
  line-height: 18px;
  color: #ffffff;
  text-decoration: none;
  padding: 0 10px;
}

.navbar-links {
  display: block;
  position: relative;
  padding-bottom: 5px;
  color: #fff;
}

.navbar-links::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  background: #fff;
  border-radius: 23px 10px 0px 0px;
  transition: 0.3s;
}

.navbar-links:hover::before {
  transform: scaleX(1);
}

.navbar-mobile {
  background: rgba(44, 52, 66, 0.9);
  backdrop-filter: blur(12.5px);
  padding-top: 30px;
  padding-bottom: 25px;
  display: none;
  padding-left: 15px;
  padding-right: 30px;
  position: relative;
  z-index: 2;
}

.navbar-close {
  background: #f5e1b2;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 50%;
  position: relative;
  left: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  top: 3%;
}

.navbar-arif {
  width: 150px;
}

.navbar-icon {
  width: 35px;
}

.navbar-mobile-button {
  background-color: transparent;
  border: none;
  width: 45px;
}
.navbar-logo {
  width: 35px;
}

.navbar-mobile .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-modal-list {
  list-style: none;
  margin-top: 35px;
}

.navbar-modal-item {
  margin: 10px 0;
}

.navbar-modal-link {
  color: #000;
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 15.1852px;
  line-height: 18px;
  text-decoration: none;
}
