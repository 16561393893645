.nav {
  background: #222936;
  position: fixed;
  z-index: 1001;
  width: 100%;
  top: 0;
}

.nav .nav-item .nav__current-lang {
  color: #fce8bd;
}
.nav .nav-item .nav__lang {
  color: #fce8bd;
}

.nav-arif-img {
  object-fit: contain;
}

.nav-list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 1140px; */
  margin: 10px auto;
}

.App .nav .nav-item .languagee {
  color: #fce8bd;
}

.nav .nav-item {
  margin-top: -5px;
}

.nav .navbar-item {
  margin: 0 10px;
}

.nav .nav-link {
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 15.1852px;
  line-height: 18px;
  color: #fce8bd;
  text-decoration: none;
  padding: 0 10px;
  padding-bottom: 5px;
}

.navbar-links {
  display: block;
  position: relative;
  padding-bottom: 5px;
}

.navbar-links::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  background: #fff;
  border-radius: 23px 10px 0px 0px;
  transition: 0.3s;
}

.navbar-links:hover::before {
  transform: scaleX(1);
}
