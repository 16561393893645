.modalContainers {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0%;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(18px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modals .modals {
  background: rgba(0, 0, 0, 0.5);
  padding: 2rem;
  transition: all 0.4s ease;
}

.modal_contents {
  width: 400px;
  height: 150px;
  border-radius: 12px;
  padding: 30px 40px;
  background: #fce8bd;
}
.modalContainers.shows {
  visibility: visible;
  transition: all 0.2s ease !important;
}

.modalContainers.shows .modals {
  opacity: 1;
  animation-name: fadeon;
  animation-iteration-count: 1;
  animation-duration: 0.5s;
  display: block;
}
